import React from "react"
import Utils from "../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout/Layout/Layout"
import SEO from "../components/SEO/SEO"
import { Link } from "gatsby"

// import { store, setT } from "../Store/Store"
// store.dispatch(setT(t))

const InternalErrorPage = ({ location }) => {
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        return (
          <Layout
            origin={location.origin}
            originPage="50x"
            commonContentMap={commonContentMap}
          >
            <SEO
              title="500: Internal server error"
              keywords={[`lotta mae`, `error page`]}
            />
            <div className="container cart-link">
              <div className="row">
                <div className="h1 col-12 mb-4 text-center text-uppercase product-text-resize">
                  Server error
                </div>
                <div className="col-12 mb-5 text-center">
                  We're sorry, something seems to have gone wrong. Try to
                  refresh this page or feel free to contact us if the problem
                  persists.
                </div>
                <div className="col-12 mb-6 text-center">
                  <Link to="/" className="btn btn-dark">
                    Go to home
                  </Link>
                </div>
              </div>
            </div>
          </Layout>
        )
      }}
    />
  )
}

export default InternalErrorPage

const pageQuery = graphql`
  {
    commonContent: commonContentEngb {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`
